import { createTheme } from '@mui/material'
import { blue, green, orange, pink, purple, red } from '@mui/material/colors'

let customTheme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
})

customTheme = createTheme(customTheme, {
  // Custom colors created with augmentColor go here
  palette: {
    red: customTheme.palette.augmentColor({ color: { main: red[700] } }),
    pink: customTheme.palette.augmentColor({ color: { main: pink[700] } }),
    orange: customTheme.palette.augmentColor({ color: { main: orange[700] } }),
    purple: customTheme.palette.augmentColor({ color: { main: purple[700] } }),
    green: customTheme.palette.augmentColor({ color: { main: green[700] } }),
    blue: customTheme.palette.augmentColor({ color: { main: blue[700] } }),
    black: customTheme.palette.augmentColor({ color: { main: '#000000' } }),
    lightgray: customTheme.palette.augmentColor({ color: { main: '#ececec' } }),
    darkgray: customTheme.palette.augmentColor({ color: { main: '#3e3e3e' } }),
    // salmon: theme.palette.augmentColor({ color: { main: '#FF5733' } }),
    // indigo: theme.palette.augmentColor({ color: { main: indigo[700] } }),
    // teal: theme.palette.augmentColor({ color: { main: teal[700] } }),
  },
})

export { customTheme }