import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List as MaterialList,
  ListItemButton,
  ListItemText,
  Stack,
  Typography, useTheme,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CategoryIcon from '@mui/icons-material/LocalOffer'
import { ExpandMore } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'
import { ColoredNumberField, DateDisplay } from './CustomFields'
import { useEntryContext } from './EntryContext'
import { CategorySelector, CategoryTag } from '../category'
import { fetchWithToken } from '../fetchWithToken'

const HideButton = () => {
  const { selectedEntryIds, setSelectedEntryIds, refresh } = useEntryContext()

  return (
    <Button
      onClick={async () => {
        await fetchWithToken('/api/entry/hide/', {
            ids: selectedEntryIds,
          }, 'DELETE',
        )
        setSelectedEntryIds([])
        refresh()
      }}
      startIcon={<VisibilityOffIcon/>}
      color="error"
      // variant="contained"
      sx={{ borderRadius: 20 }}
    >
      隐藏账单
    </Button>
  )
}

const AssignEntryDialog = ({ onClose, open }) => {
  const { selectedEntryDict, selectedEntryIds, setSelectedEntryIds, refresh } = useEntryContext()
  const [parentId, setParentId] = useState(null)
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'

  const handleSubmit = async () => {
    const childrenIds = selectedEntryIds.filter((id) => id !== parentId)
    await fetchWithToken('/api/entry/assign/', {
      parentId: parentId,
      childrenIds: childrenIds,
    }, 'POST')
    setSelectedEntryIds([])
    onClose()
    refresh()
  }

  const handleCancel = () => {
    setParentId(null)
    onClose()
  }

  return (
    <Dialog
      onClose={handleCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        sx={{ fontWeight: 'bold' }}>请选择1项作为上级账单</DialogTitle>
      <DialogContent>
        <MaterialList component="nav" aria-label="main mailbox folders">
          {
            Object.values(selectedEntryDict).map((entry) => (
              <ListItemButton
                selected={parentId === entry.id}
                onClick={() => {setParentId(entry.id)}}>
                <DateDisplay text={entry.datetime}/>
                <Box sx={{ width: 200 }}>
                  <CategoryTag item={entry.category} isDarkMode={isDarkMode}/>
                </Box>
                <ListItemText primary={entry.title} secondary={entry.content}
                              sx={{ 'mr': 2 }}/>
                <ColoredNumberField text={entry.amount}/>
              </ListItemButton>
            ))
          }
        </MaterialList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={!parentId}>确定</Button>
        <Button onClick={handleCancel} autoFocus>取消</Button>
      </DialogActions>


    </Dialog>
  )
}

// const AssignCategoryDialog = ({ onClose, open }) => {
//   const { categories, selectedEntryIds, setSelectedEntryIds, refresh } = useEntryContext()
//   const [categoryId, setCategoryId] = useState(null)
//   const handleSubmit = async () => {
//     await fetchWithToken('/api/entry/assign/category/', {
//       ids: selectedEntryIds,
//       categoryId: categoryId,
//     }, 'PATCH')
//     setSelectedEntryIds([])
//     onClose()
//     refresh()
//   }
//   //TODO: 弹出菜单中要能显示子类别
//
//   const handleCancel = () => {
//     setCategoryId(null)
//     onClose()
//   }
//
//   return (
//     <Dialog
//       onClose={handleCancel}
//       open={open}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description">
//       <DialogTitle
//         sx={{ fontWeight: 'bold' }}>选择类别</DialogTitle>
//       <DialogContent>
//         <MaterialList component="nav" aria-label="main mailbox folders">
//           {
//             Object.values(categories).map((item) => (
//               <ListItemButton
//                 selected={categoryId === item.id}
//                 onClick={() => {setCategoryId(item.id)}}>
//                   <CategoryTag item={item}/>
//               </ListItemButton>
//             ))
//           }
//         </MaterialList>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleSubmit} disabled={!categoryId}>确定</Button>
//         <Button onClick={handleCancel} autoFocus>取消</Button>
//       </DialogActions>
//     </Dialog>
//   )
// }


const AddParentButton = () => {
  const { selectedEntryIds } = useEntryContext()
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => {
        const url = `/entry/combine?ids=${selectedEntryIds.join(',')}`
        navigate(url)
      }}
      startIcon={<AddCircleIcon/>}
      color="primary"
      // variant="contained"
      size="small"
      sx={{ borderRadius: 20 }}
    >
      创建上级账单
    </Button>
  )
}
const AssignEntryButton = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<AddCircleIcon/>}
        color="primary"
        // variant="contained"
        size="small"
        sx={{ borderRadius: 20 }}
      >
        指定上级账单
      </Button>
      <AssignEntryDialog open={open} onClose={() => setOpen(false)}/>
    </>
  )
}

const AssignCategoryButton = ({type='O'}) => {
  const { selectedEntryIds, setSelectedEntryIds, refresh } = useEntryContext()
  const handleClick = async (value) => {
    await fetchWithToken('/api/entry/assign/category/', {
      ids: selectedEntryIds,
      categoryId: value,
    }, 'PATCH')
    setSelectedEntryIds([])
    refresh()
  }
  return (
    <CategorySelector
      onChange={handleClick}
      type={type}
      sx={{ width: 200 }}
    >
      <Button
        // onClick={() => {} }
        startIcon={<CategoryIcon/>}
        endIcon={<ExpandMore/>}
        color="primary"
        // variant="contained"
        size="small"
        sx={{ borderRadius: 20 }}
      >
        设置类别
      </Button>
      {/*<AssignCategoryDialog open={open} onClose={() => {setOpen(false)}}/>*/}
    </CategorySelector>
  )
}

const FreeEntryButton = () => {
  const { selectedEntryIds, setSelectedEntryIds, refresh } = useEntryContext()
  return (
    <Button
      onClick={async () => {
        await fetchWithToken('/api/entry/assign/', {
          parentId: -1,
          childrenIds: selectedEntryIds,
        }, 'POST')
        refresh()
        setSelectedEntryIds([])
      }}
      startIcon={<RemoveCircleIcon/>}
      color="primary"
      // variant="contained"
      size="small"
      sx={{ borderRadius: 20 }}
    >
      从上级账单中独立
    </Button>
  )
}

const SetEditModeButton = () => {
  const { isEditMode, setIsEditMode } = useEntryContext()
  return (
    <Button
      onClick={() => setIsEditMode(!isEditMode)}
      startIcon={<EditIcon/>}
      color={isEditMode ? 'success' : 'error'}
      variant="contained"
      sx={{ borderRadius: 20 }}
    >
      {isEditMode ? '退出编辑' : '批量编辑'}
    </Button>
  )
}

const CreateButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate('/entry/create')}
      startIcon={<AddCircleIcon/>}
      color="primary"
      variant="contained"
      sx={{ borderRadius: 20 }}
    >
      创建账单
    </Button>
  )

}

export const EntryTopBar = ({ panel, setPanel }) => {
  const { selectedEntryDict, setSelectedEntryDict ,selectedEntryIds ,
    setSelectedEntryIds, refresh, statisticData } = useEntryContext()
  useEffect(() => {
    refresh();
  }, []); // Empty dependency array means this effect runs once on mount


  const levelsList = Object.values(selectedEntryDict).map(entry => entry.level)
  const isAllLevelNotOne = levelsList.every((val) => val !== 1)
  const isAllLevelSame = levelsList.every((val, i, arr) => val === arr[0])
  const hasOneOrTwoLevels = new Set(levelsList).size <= 2 &&
    levelsList.length > 1
  const typesList = Object.values(selectedEntryDict).map(entry => entry.type)
  const isAllTypeSame = typesList.every((val, i, arr) => val === arr[0])
  const sameType = isAllTypeSame ? typesList[0] : 'O'

  const onCancel = () => {
    setSelectedEntryIds([])
    setSelectedEntryDict({})
  }

  return selectedEntryIds.length ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider',
        mb: 1,
        bgcolor: 'background.paper',
        height: '60px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <IconButton onClick={onCancel}>
          <HighlightOffIcon fontSize="large" color="primary"/>
        </IconButton>
        <Typography variant="h6"
                    color="primary"> 已选择 {selectedEntryIds.length} 项</Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        {isAllLevelSame && <AddParentButton/>}
        {hasOneOrTwoLevels && <AssignEntryButton/>}
        {isAllLevelNotOne && isAllLevelSame && <FreeEntryButton/>}
        {/*<DeleteButton/>*/}
        {isAllTypeSame && <AssignCategoryButton type={sameType}/>}
        <HideButton/>
      </Stack>
    </Box>
  ) : (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      mb: 1,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      height: '60px',
    }}>
      <Box sx={{ display: {
        xs: 'none', md: 'flex'
        }, flexGrow: 1, gap: 1 }}>
        {!!statisticData && Object.keys(statisticData).length &&
          <Box sx={{ display: 'flex', flexGrow: 1, gap: 2, flexWrap: 'wrap' }}>
            <Typography variant="h5">共{`${statisticData.total.count}`}笔</Typography>
            <Box sx={{ display: { md: 'none', lg:'flex' } }}>
              <Typography variant="h6">收入：</Typography>
              <ColoredNumberField fontSize={18} text={statisticData.income.amount}/>
              <Typography variant="h6" sx={{ ml: '2px' }}>({`${statisticData.income.count}`}笔)</Typography>
            </Box>
            <Box sx={{ display: { md: 'none', lg:'flex' } }}>
              <Typography variant="h6">支出：</Typography>
              <ColoredNumberField fontSize={18} text={statisticData.expense.amount}/>
              <Typography variant="h6" sx={{ ml: '2px' }}>({`${statisticData.expense.count}`}笔)</Typography>
            </Box>
            <Box sx={{ display: { md: 'none', lg:'flex' } }}>
              <Typography variant="h6">净收入：</Typography>
              <ColoredNumberField fontSize={18} text={statisticData.total.amount}/>
            </Box>
          </Box>
        }
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <CreateButton/>
        <SetEditModeButton/>
      </Box>
    </Box>
  )
}
