import React, { createContext, useState, useEffect } from 'react'
import { getWithToken } from '../fetchWithToken'

export const EntryContext = createContext({})

export const EntryContextProvider = ({ children }) => {
  const [curDate, setCurDate] = useState(new Date())
  const [selectedEntryIds, setSelectedEntryIds] = useState([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [selectedEntryDict, setSelectedEntryDict] = useState({})
  const [entries, setEntries] = useState([])
  const [categories, setCategories] = useState([])
  const [entryCount, setEntryCount] = useState(0)
  const [statisticData, setStatisticData] = useState({})

  const firstDayOfMonth = new Date(curDate.getFullYear(), curDate.getMonth(),
    1)
  // 'en-CA' format is 'yyyy-mm-dd'
  const dateStart = firstDayOfMonth.toLocaleDateString('en-CA').split('T')[0]
  const dateEnd = curDate.toLocaleDateString('en-CA').split('T')[0]

  const [entryFilter, setEntryFilter] = useState({
    level: 1,
    page_size: 100,
    page: 1,
    ordering: '-datetime',
    detail: true,
    date_start: localStorage.getItem('date_start') || dateStart,
    date_end: localStorage.getItem('date_end') || dateEnd,
  })
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    setSelectedEntryIds([])
    setSelectedEntryDict({})
    refresh()
  }, [entryFilter])

  const refresh = async () => {
    refreshEntry()
    refreshCategory()
    refreshStatistic()
  }

  const refreshEntry = async () => {
    getWithToken('/api/entry/', entryFilter).then((data) => {
      if (data) {
        // console.log('entries', data.results)
        setEntryCount(data.count)
        setEntries(data.results)
      }
    })
  }

  const refreshCategory = async () => {
    const categoryFilter = { 'level': 1, 'detail': true }
    if (entryFilter.date_start) {
      categoryFilter.date_start = entryFilter.date_start
    }
    if (entryFilter.date_end) {
      categoryFilter.date_end = entryFilter.date_end
    }
    getWithToken('/api/category/', categoryFilter).then((data) => {
      if (data)
        setCategories(data)
    })
  }

  const refreshStatistic = async () => {
    const statisticFilter = {}
    if (entryFilter.date_start) {
      statisticFilter.date_start = entryFilter.date_start
    }
    if (entryFilter.date_end) {
      statisticFilter.date_end = entryFilter.date_end
    }
    if (entryFilter.categories && entryFilter.categories.length) {
      statisticFilter.categories = entryFilter.categories
    }
    getWithToken('/api/statistic/basic/', statisticFilter).then((data) => {
      if (data)
        setStatisticData(data)
    })
  }

  return (
    <EntryContext.Provider value={
      {
        selectedEntryIds,
        setSelectedEntryIds,
        selectedCategoryIds,
        setSelectedCategoryIds,
        selectedEntryDict,
        setSelectedEntryDict,
        entries,
        setEntries,
        categories,
        setCategories,
        entryFilter,
        setEntryFilter,
        statisticData,
        setStatisticData,
        entryCount,
        setEntryCount,
        refresh,
        refreshEntry,
        refreshCategory,
        isEditMode,
        setIsEditMode,
      }
    }>
      {children}
    </EntryContext.Provider>
  )
}

export const useEntryContext = () => React.useContext(EntryContext)
