import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Collapse,
  Typography, TablePagination, useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

import { CategoryTag, CategoryTagById } from '../category'
import { ColoredNumberField, DateDisplay, TimeDisplay } from './CustomFields'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { useEntryContext } from './EntryContext'
import { useNavigate } from 'react-router-dom'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters  {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&. MuiAccordion-root': {
    padding: 0,
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }}/>}
    {...props}
  />
))(({ theme }) => ({
  // flexDirection: 'row-reverse',
  padding: '0px 0px',
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
  '&.Mui-disabled': {
    opacity: 1, //TODO: 使即使disabled也能点击CheckBox
    pointerEvents: 'auto',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: '0px 20px 10px',
  '&:first-child': {
    borderTop: 'none',
  },
}))

const EntryItem = ({ entry }) => {
  const {
    selectedEntryIds,
    setSelectedEntryIds,
    setSelectedEntryDict,
    isEditMode,
  } = useEntryContext()
  const theme = useTheme()
  const navigate = useNavigate()
  const isDarkMode = theme.palette.mode === 'dark'
  const isSelected = selectedEntryIds.includes(entry.id)

  const onCheckBoxClick = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    if (isSelected) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entry.id))
      setSelectedEntryDict(prevDict => {
        const newDict = { ...prevDict }
        delete newDict[entry.id]
        return newDict
      })
    } else {
      setSelectedEntryIds([...selectedEntryIds, entry.id])
      setSelectedEntryDict(prevDict => ({ ...prevDict, [entry.id]: entry }))
    }
  }
  return (
    <Accordion key={entry.id}>
      <AccordionSummary
        expandIcon={!!entry.children ? <ExpandMore/> : <div
          style={{ width: '24px', height: '24px' }}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        disabled={!entry.children}
      >
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            cursor: 'pointer',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            {/*<TimeDisplay text={entry.datetime} />*/}
            { //TODO: 使CheckBox的选中和取消选中不会触发accordion的展开和收起
              isEditMode && <Checkbox
                checked={isSelected}
                onChange={onCheckBoxClick}
                inputProps={{ 'aria-label': 'checkbox-controlled' }}
              />
            }
            <ListItemText
                onClick={() => {
                  navigate(`/entry/${entry.id}`)
                }}
              sx={{ paddingLeft: '16px'}}
              primary={
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px 0px',
                }}>
                  {entry.level === 1 && <CategoryTag
                    item={entry.category}
                    filled={!!entry.children}
                    badgeContent={entry.children
                      ? entry.children.length
                      : 0}
                    size="small"
                    isDarkMode={isDarkMode}
                  />}
                  {entry.title}
                </Box>
              }
              secondary={entry.content}
            />
          </Box>
          <ColoredNumberField text={entry.amount}/>
        </Box>
      </AccordionSummary>
      {entry.children && entry.children.length > 0 &&
        <AccordionDetails>
          {entry.children.map((child) => (
            <EntryItem entry={child}/>
          ))}
        </AccordionDetails>
      }
    </Accordion>
  )
}

export const EntryTable = () => {
  const { entries, entryCount, entryFilter, setEntryFilter } = useEntryContext()
  const [open, setOpen] = useState({})

  const handleClick = (id) => {
    setOpen(prevOpen => {
      return { ...prevOpen, [id]: !prevOpen[id] }
    })
  }

  // Group data by datetime
  const groupedData = entries.reduce((groups, item) => {
    const date = item.datetime.split('T')[0] // assuming datetime is in ISO format
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(item)
    return groups
  }, {})

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box sx={{ width: '100%' }}>
        {Object.keys(groupedData).map(date => (
          <Card key={date} sx={{ marginBottom: 1 }}>
            <DateDisplay text={date}/>
            {groupedData[date].map((entry) => <EntryItem entry={entry}/>)}
          </Card>
        ))}
      </Box>
      {Object.keys(groupedData).length > 0 &&
        <TablePagination
          component="div"
          count={entryCount}
          page={entryFilter.page - 1}
          onPageChange={(e, page) => {
            // console.log(page)
            setEntryFilter({ ...entryFilter, page: page + 1 })
          }}
          rowsPerPage={entryFilter.page_size}
          onRowsPerPageChange={(e) => {
            setEntryFilter({ ...entryFilter, page_size: e.target.value })
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      }
    </Box>
  )
}
