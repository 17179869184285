export const PAYMENT = [
  { id: 'C', name: '现金' },
  { id: 'Z', name: '支付宝' },
  { id: 'W', name: '微信' },
  { id: 'B', name: '银行卡' },
  { id: 'M', name: '混合' },
  { id: 'O', name: '其他' },
]

export const BILL_TYPE = [
  { id: 'I', name: '收入' },
  { id: 'E', name: '支出' },
  { id: 'O', name: '无' },
]

export const WEEK_DAY = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  0: '周日',
}