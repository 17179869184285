import React, { useState } from 'react'

import { useGetList } from 'react-admin'

import { Cascader, ConfigProvider } from 'antd'

import {
  AttachMoney, Payment, MonetizationOn, LocalAtm, ErrorOutline, Euro,
  CreditCard, ShoppingCart, Flight, SportsEsports, Home, DirectionsCar, Restaurant,
  LocalCafe, Dining, Fastfood, TakeoutDining,
  AirportShuttle, Luggage, FoodBank, Attractions,
  HelpOutline,
} from '@mui/icons-material'

import {
  Badge,
  Box,
  Chip,
  List,
  ThemeProvider,
  Typography, useTheme,
} from '@mui/material'
import { ColoredNumberField } from './entry/CustomFields'
import { useEntryContext } from './entry/EntryContext'
import { customTheme } from './customTheme'

const iconMap = {
  '固定收入': <AttachMoney/>,
  '退款转账收入': <Payment/>,
  '偶然收入': <MonetizationOn/>,
  '日常支出': <LocalAtm/>,
  '偶然支出': <ErrorOutline/>,
  '固定支出': <Euro/>,
  '会员充值': <CreditCard/>,
  '重要购物': <ShoppingCart/>,
  '旅游出行': <Flight/>,
  '娱乐': <SportsEsports/>,
  '生活': <Home/>,
  '交通': <DirectionsCar/>,
  '饮食': <Restaurant/>,
  '食堂': <LocalCafe/>,
  '到店': <Dining/>,
  '外卖': <TakeoutDining/>,
  '休闲零食': <Fastfood/>,
  '旅行交通': <AirportShuttle/>,
  '酒店住宿': <Luggage/>,
  '旅行餐饮': <FoodBank/>,
  '景点娱乐': <Attractions/>,
  '未分类': <HelpOutline/>,
}

const colorMap = (record, isDarkMode=false) => {
  if (record.type === 'I') {
    if (record.level === 1) { return 'red' }
    if (record.level === 2) { return 'pink' }
    if (record.level === 3) { return 'orange' }
  }
  if (record.type === 'E') {
    if (record.level === 1) { return 'green' }
    if (record.level === 2) { return 'blue' }
    if (record.level === 3) { return 'purple' }
  }
  return isDarkMode ? 'lightgray' : 'darkgray'
}

export const CategoryTag = React.memo(({
  item, filled = false,
  badgeContent = 0,
  size = 'medium',
  isDarkMode = false,
}) => {
  return !!item && (
    <ThemeProvider theme={customTheme}>
      <Badge
        sx={{ mr: 1 }}
        badgeContent={badgeContent}
        color={colorMap(item, isDarkMode)}
      >
        <Chip
          icon={iconMap[item.name]}
          label={item.name}
          color={colorMap(item, isDarkMode)}
          variant={filled ? 'filled' : 'outlined'}
          size={size}
        />
      </Badge>
    </ThemeProvider>
  )
})

export const CategorySelector = ({
  onChange,
  curCategory = null,
  type = 'O',
  children=null,
}) => {
  const { data } = useGetList(
    'category',
    { pagination: { page: 1, perPage: 100 }, filter: { level: 1, type: type, detail: true } },
  )
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'
  const [isInitial, setInitial] = useState(true)
  const addLabelToOption = option => {
    // 为当前选项添加label
    const newOption = {
      ...option,
      label: <CategoryTag item={option} isDarkMode={isDarkMode}/>,
    };
    // 如果当前选项有子选项，那么递归地为每个子选项添加label
    if (newOption.children) {
      newOption.children = newOption.children.map(addLabelToOption);
    }
    return newOption;
  }
  return !!data && (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 40,
          colorBgContainer: 'transparent',
        },
        components: {
          Cascader: {
            dropdownHeight: 200,
            controlItemWidth: 180,
            menuPadding: 4,
            optionPadding: '4px 8px',
          },
        },
      }}
    >
      <Cascader
        options={data.map(addLabelToOption)}
        onChange={(value) => {
          onChange(value ? value[value.length - 1] : null)
          setInitial(false)
        }}
        placeholder="选择类别"
        fieldNames={{ label: 'label', value: 'id', children: 'children' }}
        changeOnSelect
        size="large"
        expandTrigger="hover"
        defaultValue={curCategory}
        displayRender={(label, selectedOptions) => (
          isInitial ?
            <CategoryTag item={curCategory} isDarkMode={isDarkMode}/> :
            <CategoryTag
              item={selectedOptions[selectedOptions.length - 1]}
              isDarkMode={isDarkMode}
            />
        )}
      >
        {children}
      </Cascader>
    </ConfigProvider>
  )
}

export const CategoryItem = ({ item }) => {
  const { entryFilter, setEntryFilter, refresh } = useEntryContext()
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'
  const isSelected = entryFilter.categories &&
    entryFilter.categories.includes(item.id)

  const handleCategoryClick = async (e) => {
    e.stopPropagation()
    if (!isSelected) {
      // if item.id not in entryFilter.category, add it
      if (!entryFilter.categories) {
        entryFilter.categories = []
      }
      entryFilter.categories.push(item.id)
      // recursively push all the children id
      const pushChildrenIds = (children) => {
        if (children && children.length > 0) {
          children.forEach((child) => {
            entryFilter.categories.push(child.id)
            pushChildrenIds(child.children)
          })
        }
      }
      pushChildrenIds(item.children)
      setEntryFilter(entryFilter)
    } else if (entryFilter.categories) {
      // if item.id and children of item in entryFilter.categories, remove it
      const index = entryFilter.categories.indexOf(item.id)
      if (index > -1) {
        entryFilter.categories.splice(index, 1)
      }
      // recursively remove all the children id
      const removeChildrenIds = (children) => {
        if (children && children.length > 0) {
          children.forEach((child) => {
            const childIndex = entryFilter.categories.indexOf(child.id)
            if (childIndex > -1) {
              entryFilter.categories.splice(childIndex, 1)
            }
            removeChildrenIds(child.children)
          })
        }
      }
      removeChildrenIds(item.children)
      // if entryFilter.categories is empty, delete it
      if (entryFilter.categories.length === 0) {
        delete entryFilter.categories
      }
      setEntryFilter(entryFilter)
    }
    // console.log('entryFilter', entryFilter)
    refresh()
  }

  return (
    <Box
      component="div"
      role="button"
      onClick={handleCategoryClick}
      sx={{ p: '0px 0px 0px 10px' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <ThemeProvider theme={customTheme}>
          <Box sx={{
            p: '5px 0px',
            display: 'flex',
            borderRadius: '0px 20px 20px 0px',
            backgroundColor: isSelected ? (
              isDarkMode ? customTheme.palette.darkgray.main :
              customTheme.palette.lightgray.main
            ): 'transparent',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: isSelected ? customTheme.palette.lightgray.main : 'action.hover',
            },
          }}>
            <CategoryTag item={item} isDarkMode={isDarkMode}/>
            { !!item.count &&
              <Box sx={{ display: 'flex', flexGrow: 1, pr: 1, justifyContent: 'flex-end' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>({item.count}笔)</Typography>
                <ColoredNumberField text={item.amount}/>
              </Box>
            }
          </Box>
        </ThemeProvider>
        {item.children && item.children.length > 0 &&
          <List>
            {item.children.map((child) => (
              <CategoryItem item={child}/>
            ))}
          </List>
        }
      </Box>
    </Box>
  )
}
