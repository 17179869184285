import React from 'react'
import {Box, Divider, IconButton, Typography,} from '@mui/material'
import {
    AutocompleteInput,
    DateTimeInput,
    DeleteButton,
    Edit,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useGetOne,
    useNotify,
} from 'react-admin'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {useTheme} from '@mui/material/styles'
import {useNavigate} from 'react-router-dom'

import {PAYMENT} from "../constant";
import {LevelSelector, MainCategorySelector, TypeSelector} from "./EntryCreateForm";
import {useEntryContext} from "./EntryContext";
import {fetchWithToken} from "../fetchWithToken";

const CustomToolbar = props => (
    <Toolbar {...props} >
        <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
                label="保存"
                alwaysEnable
                // Add your custom props here
            />
            <DeleteButton
                label="删除"
                // Add your custom props here
            />
        </Box>
    </Toolbar>
);

export const EntryEdit = ({id}) => {
    const {data} = useGetOne('entry', {id: id}, {enabled: !!id})
    const navigate = useNavigate()
    const theme = useTheme()
    const {refresh} = useEntryContext()
    const notify = useNotify()

    const handleSave = (values) => {
        const {parent, children, id, ...rest} = values
        console.log('transform', {
            ...rest,
            content: rest.content || '',
            category: typeof rest.category === 'object' ? rest.category.id : rest.category,
        })
        fetchWithToken(`/api/entry/${id}/`, {
            ...rest,
            category: typeof rest.category === 'object' ? rest.category.id : rest.category,
        }, 'PATCH').then(({data}) => {
            // Do something with the response data
            console.log('data', data)
            notify('更新成功')
            navigate('/entry')
            refresh()
        }).catch((error) => {
            // Do something with the error
            console.log('error', error)
            notify('更新失败')
        })
    };

    if (!data) return null
    return (
        <Box sx={{p: 1, bgcolor: theme.palette.background.paper}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>

                <Typography variant="h5" sx={{padding: [0, 1]}}>
                    账单详情-{id}
                </Typography>
                <IconButton onClick={() => navigate('/entry')}>
                    <HighlightOffIcon fontSize="large" color="primary"/>
                </IconButton>
            </Box>

            <Divider flexItem sx={{my: 1}}/>

            <Edit resource="entry" id={id}>
                <SimpleForm
                    toolbar={<CustomToolbar/>}
                    redirect="list"
                    onSubmit={handleSave}
                    defaultValues={
                        {
                            title: data.title,
                            content: data.content,
                            amount: data.amount,
                            datetime: data.datetime,
                            payment: data.payment,
                            level: data.level,
                            category: data.category.id,
                        }
                    }
                >
                    <Box sx={{'width': 1}} spacing={1}>

                        <TextInput source="title" label="标题"
                                   resettable isRequired fullWidth/>
                        <TextInput source="content" label="详情" fullWidth multiline/>

                        <NumberInput sx={{'width': 150, 'display': 'block'}}
                                     source="amount" label="金额" isRequired
                        />

                        <LevelSelector/>

                        <DateTimeInput source={'datetime'} label="时间" isRequired sx={{'display': 'block'}}/>

                        <SelectInput
                            source="payment" label="支付方式" required choices={PAYMENT}/>

                        <ReferenceInput source="parent" reference="entry">
                            <AutocompleteInput source="id" label="上级账单"
                                               disabled={true}
                            />
                        </ReferenceInput>

                        <Typography sx={{'mb': 1}}>账单类别</Typography>

                        <MainCategorySelector curCategory={data.category}/>
                        <ReferenceArrayInput source="label" reference="label" label="标签"/>

                        <TypeSelector/>
                    </Box>
                </SimpleForm>
            </Edit>
        </Box>
    )
}
