import * as React from 'react'
import {Layout, Menu} from 'react-admin'
import { AppBar as MuiAppBar, UserMenu, MenuItemLink , TitlePortal} from 'react-admin'
import { NavLink, useLocation } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import EqualizerIcon from '@material-ui/icons/Equalizer';

const Breadcrumb = () => {
  const location = useLocation()
  const segments = location.pathname.split('/').filter((s) => s !== '')

  const breadcrumbs = segments.map((segment, index) => {
    const path = `/${segments.slice(0, index + 1).join('/')}`
    return (
      <span key={path} style={{ color: 'text.primary', textDecoration: 'none' }}>
        <NavLink style={{ color: 'text.primary', textDecoration: 'none' }} to={path}>
          {segment}
        </NavLink>
        {index < segments.length - 1 && ' > '}
      </span>
    )
  })

  return <div style={{'marginTop': 10}}>{breadcrumbs}</div>
}

const AppBar = (props) => {
  return (
    <MuiAppBar {...props}>
      <TitlePortal />
      <UserMenu label="User" icon={<SettingsIcon/>}>
        <MenuItemLink to="/profile" primaryText="Profile"/>
        <MenuItemLink to="/logout" primaryText="Logout"/>
      </UserMenu>
    </MuiAppBar>
  )
}

const CustomMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.ResourceItem name="entry" />
    <Menu.ResourceItem name="upload" />
    <Menu.ResourceItem name="action" />
    <Menu.Item to="/statistics" primaryText="账单统计" leftIcon={<EqualizerIcon />} />
  </Menu>
);

const CustomLayout = (props) => (
  <Layout {...props} appBar={AppBar} menu={CustomMenu}>
    <Breadcrumb/>
    {props.children}
  </Layout>
)

export default CustomLayout
