import { apiUrl } from "./dataProvider"
export const fetchWithToken = async (url, body, method='POST') => {
  const token = localStorage.getItem('access')
  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status === 401) {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      localStorage.clear()
      window.location.href = '/login'
    } else if (res.status === 204) {
      return {}
    } else if (res.status === 200) {
      return res.json()
    }
  })
}

export const getWithToken = async (url, params) => {
  const token = localStorage.getItem('access')
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${Array.isArray(params[k]) ? params[k].join(',') : encodeURIComponent(params[k])}`)
    .join('&');
  // console.log('params', params)
  // console.log('query', query)
  return fetch(`${apiUrl}${url}?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status === 401) {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      localStorage.clear()
      window.location.href = '/login'
    } else if (res.status === 204) {
      return {}
    } else if (res.status === 200){
      return res.json()
    }
  })
}
