import {Card, CardContent, CardHeader, Tab, Tabs, Typography} from "@mui/material";
import {YearlyProfit} from "./charts/YearlyProfit";
import {useState} from "react";
import {YearlyCategory} from "./charts/YearlyCategory";

export const Statistics = () => {
  const [year, setYear] = useState(2023);
  const onTabChange = (event, newValue) => {
    console.log(newValue);
    setYear(newValue);
  }
  return (
    <Card>
      <CardHeader title="统计面板"/>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          年度收支统计
        </Typography>
        <Tabs title={"选择年份"} value={year} onChange={onTabChange}>
          <Tab value={2021} label="2021年"/>
          <Tab value={2022} label="2022年"/>
          <Tab value={2023} label="2023年"/>
          <Tab value={2024} label="2024年"/>
          <Tab value={2025} label="2025年"/>
        </Tabs>
        <YearlyProfit year={year}/>
        {/*<YearlyCategory year={year}/>*/}
      </CardContent>
    </Card>
  );
};
