import React from 'react'
import { useTheme } from '@mui/material/styles'
import { EntryContextProvider } from './EntryContext'
import { EntryTopBar } from './EntryButtons'
import { EntryTable } from './EntryTable'
import { EntryFilterSidebar } from './EntryFilterSideBar'
import { Box, Card, Divider } from '@mui/material'

export const EntryList = () => {
  const theme = useTheme()
  return (
    <EntryContextProvider>
      <Box sx={{
        p: 1,
        bgcolor: theme.palette.background.paper,
      }}>
        <EntryTopBar/>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <EntryTable/>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }}/>
          <EntryFilterSidebar/>
        </Box>
      </Box>
    </EntryContextProvider>
  )
}
