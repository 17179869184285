import { Box, ButtonBase, Typography, useTheme } from '@mui/material'
import React from 'react'
import {customTheme} from '../customTheme'
import { WEEK_DAY } from '../constant'

export const DateDisplay = ({ text, level = 1 }) => {
  const theme = useTheme()
  if (!text) {
    return null
  }
  const color = theme.palette.mode === 'dark' ? 'lightblue' : 'blue';
  const backgroundColor = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)'
  const datetime = new Date(text)
  return (
    <Box minWidth={100} sx={{ 'backgroundColor': backgroundColor }}>
      <Typography sx={{ color: color, pl: 1, pt: 1, pb: 1 }}>
        {datetime.getMonth() + 1}月{datetime.getDate()}日
        &nbsp;
        {WEEK_DAY[datetime.getDay()]}
      </Typography>
    </Box>
  )
}

export const TimeDisplay = ({ text }) => {
  if (!text) {
    return null
  }
  const datetime = new Date(text)
  const hours = String(datetime.getHours()).padStart(2, '0');
  const minutes = String(datetime.getMinutes()).padStart(2, '0');
  return (
    <Typography color="text.primary">
      {hours}:{minutes}
    </Typography>
  )
}

export const CustomTitleField = ({ text, level = 1 }) => {
  const fontSize = 18 - level * 2
  const paddingLeft = `${2 * (level - 1)}em`
  const sx = {
    fontSize: fontSize,
    paddingLeft: paddingLeft,
    fontWeight: level === 1 ? 'bold' : 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
  }
  return (
    <Typography ellipsis={true} style={sx}> {text} </Typography>
  )
}

export const CustomContentField = ({ text, level = 1 }) => {
  const fontSize = 18 - level * 2
  const paddingLeft = `${2 * (level - 1)}em`
  const sx = {
    fontSize: fontSize,
    paddingLeft: paddingLeft,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
  }
  return (
    <Typography ellipsis={true} style={sx}> {text} </Typography>
  )
}

export const ColoredNumberField = ({ text, fontSize= 16 }) => {
  const theme = customTheme
  const colorError = theme.palette.error.main
  const colorSuccess = theme.palette.success.main
  const sx = {
    fontSize: fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  }

  return text > 0 ? (
    <Typography sx={{ color: colorError, ...sx }}>
      +{text.toFixed(2)}
    </Typography>
  ) : (
    text < 0 ? (
      <Typography
        sx={{ color: colorSuccess, ...sx }}>
        {text.toFixed(2)}
      </Typography>
    ) : (
      <Typography sx={{ fontSize: 16, ...sx }}>
        {text.toFixed(2)}
      </Typography>
    )
  )
}