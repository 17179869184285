import drfProvider, {
  jwtTokenAuthProvider,
  fetchJsonWithAuthJWTToken,
} from 'ra-data-django-rest-framework'

export const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

export const authProvider = jwtTokenAuthProvider({obtainAuthTokenUrl: `${apiUrl}/api/token/`})
export const dataProvider = drfProvider(`${apiUrl}/api`, fetchJsonWithAuthJWTToken)

