import logo from './logo.svg'
import { Route } from 'react-router-dom'
import './App.css'
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  CustomRoutes,
  radiantLightTheme,
  radiantDarkTheme,
} from 'react-admin'
import SellIcon from '@mui/icons-material/Sell'
import TurnedInIcon from '@mui/icons-material/TurnedIn'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import SettingsIcon from '@mui/icons-material/Settings'
import { authProvider, dataProvider } from './dataProvider'
import { Dashboard } from './dashboard'
import { Statistics } from './statistics'
import CustomLayout from './layout'
import { UploadPage } from './upload'
import { EntryList } from './entry/EntryList'
import { EntryCombine, EntryCreate } from './entry/EntryCreate'

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
    layout={CustomLayout}
    theme={radiantLightTheme}
    darkTheme={radiantDarkTheme}>
    <Resource
      name="entry"
      list={EntryList}
      create={<EntryCreate/>}
      // edit={EditGuesser}
      // show={ShowGuesser}
      options={{ label: '账单详情' }}
      icon={AttachMoneyIcon}
      recordRepresentation={(record) => `#${record.id}-${record.title}`}
    >
      <Route path="/combine" element={<EntryCombine/>}/>
    </Resource>
    <Resource
      name="upload"
      list={UploadPage}
      options={{ label: '上传账单' }}
      icon={CloudUploadIcon}
    />
    <Resource
      name="action"
      list={ListGuesser}
      edit={EditGuesser}
      show={ShowGuesser}
      options={{ label: '导入设置' }}
      icon={SettingsIcon}
    />

    <CustomRoutes>
      <Route path="/statistics" element={<Statistics/>}/>
    </CustomRoutes>
  </Admin>
)

export default App
