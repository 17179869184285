import {
  Box,
  Button,
  Card,
  CardContent, Divider,
  List,
  TextField,
  Typography,
} from '@mui/material'
import CategoryIcon from '@mui/icons-material/LocalOffer'
import EventIcon from '@mui/icons-material/Event'
import React from 'react'
import { useEntryContext } from './EntryContext'
import { CategoryItem } from '../category'
import { matchPath, useLocation } from 'react-router-dom'
import { EntryEdit } from './EntryEdit'

export const EntryFilterSidebar = () => {
  const { categories, entryFilter, setEntryFilter } = useEntryContext()
  const location = useLocation()
  const match = matchPath('/entry/:id', location.pathname)

  const handleDateStartClick = (e) => {
    if (e.target.value) {
      localStorage.setItem('date_start', e.target.value) // Store the selected date in localStorage
      setEntryFilter({ ...entryFilter, 'date_start': e.target.value })
    } else {
      localStorage.removeItem('date_start') // Remove the item from localStorage if no date is selected
      const newFilter = { ...entryFilter }
      delete newFilter.date_start
      setEntryFilter(newFilter)
    }
  }
  const handleDateEndClick = (e) => {
    if (e.target.value) {
      localStorage.setItem('date_end', e.target.value) // Store the selected date in localStorage
      setEntryFilter({ ...entryFilter, 'date_end': e.target.value })
    } else {
      localStorage.removeItem('date_end') // Remove the item from localStorage if no date is selected
      const newFilter = { ...entryFilter }
      delete newFilter.date_end
      setEntryFilter(newFilter)
    }
  }

  const handleLastMonthClick = () => {
    const date_end = entryFilter.date_end ? new Date(entryFilter.date_end) : new Date()
    date_end.setDate(0)
    const date_start = new Date(date_end.getFullYear(), date_end.getMonth(), 1)
    setEntryFilter({
      ...entryFilter,
      date_start: date_start.toLocaleDateString('en-CA').split('T')[0],
      date_end: date_end.toLocaleDateString('en-CA').split('T')[0],
    })
    localStorage.setItem('date_start', date_start.toLocaleDateString('en-CA').split('T')[0])
    localStorage.setItem('date_end', date_end.toLocaleDateString('en-CA').split('T')[0])
  }

  const handleNextMonthClick = () => {
    const date_end = entryFilter.date_end ? new Date(entryFilter.date_end) : new Date()
    date_end.setMonth(date_end.getMonth() + 2)
    date_end.setDate(0)
    const date_start = new Date(date_end.getFullYear(), date_end.getMonth(),
        1)
    setEntryFilter({
      ...entryFilter,
      date_start: date_start.toLocaleDateString('en-CA').split('T')[0],
      date_end: date_end.toLocaleDateString('en-CA').split('T')[0],
    })
    localStorage.setItem('date_start', date_start.toLocaleDateString('en-CA').split('T')[0])
    localStorage.setItem('date_end', date_end.toLocaleDateString('en-CA').split('T')[0])
  }

  const handleNearestOneMonthClick = () => {
    const date_end = new Date()
    const date_start = new Date(date_end.getFullYear(), date_end.getMonth() - 1, date_end.getDate())
    setEntryFilter({
      ...entryFilter,
      date_start: date_start.toLocaleDateString('en-CA').split('T')[0],
      date_end: date_end.toLocaleDateString('en-CA').split('T')[0],
    })
    localStorage.setItem('date_start', date_start.toLocaleDateString('en-CA').split('T')[0])
    localStorage.setItem('date_end', date_end.toLocaleDateString('en-CA').split('T')[0])
  }

  return (
    <Box
      sx={{
        display: 'inline',
        // order: -1, // display on the left rather than on the right of the list
        // width: '450px',
      }}
    >
      {match ?
        <EntryEdit id={match.params.id}/>
         :
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              <EventIcon/>
              选择日期
            </Typography>
            <TextField
              type="date"
              fullWidth
              label="起始日期"
              value={entryFilter.date_start}
              onChange={handleDateStartClick}
              sx={{
                margin: '10px',
              }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
            <TextField
              type="date"
              fullWidth
              label="结束日期"
              value={entryFilter.date_end}
              onChange={handleDateEndClick}
              sx={{
                margin: '10px',
              }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
            <Button
                color="secondary"
                size="small"
                sx={{mr: 1}}
                onClick={handleLastMonthClick}
            >
              前一个月
            </Button>
            <Button
                color="secondary"
                size="small"
                sx={{mr: 1}}
                onClick={handleNextMonthClick}
            >
              后一个月
            </Button>
            <Button
                color="secondary"
                size="small"
                sx={{mr: 1}}
                onClick={handleNearestOneMonthClick}
            >
              最近一个月
            </Button>
          </CardContent>
          <Divider/>
          <CardContent>
            {categories && categories.length > 0 &&
              <List>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 1,
                }}>
                  <Typography variant="h6" gutterBottom>
                    <CategoryIcon/>
                    选择类别
                  </Typography>
                  <Button
                    // variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => setEntryFilter(
                      { ...entryFilter, categories: [] })}
                  >
                    清除选择
                  </Button>
                </Box>
                {categories.map((category) => (
                  <CategoryItem item={category}/>
                ))}
              </List>
            }
          </CardContent>
        </Card>}
    </Box>

  )
}
