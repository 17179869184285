import React, { useEffect } from 'react'
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material'
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
} from 'react-admin'

import { useFormContext, useWatch } from 'react-hook-form'
import { BILL_TYPE, PAYMENT } from '../constant'
import { CategorySelector } from '../category'

const Aside = ({ info }) => {
  return (
    <Card>
      <Box width={400} display={{ xs: 'none', lg: 'block' }} sx={{ 'p': 2 }}>
        <Typography variant={'h6'}>
          {`共有 ${info?.count} 项将被合并`}
        </Typography>
      </Box>
    </Card>
  )
}

const BottomToolbar = ({ mode }) => (
  <SaveButton
    sx={{ 'm': 2 }}
    label={mode === 'combine' ? '合并' : '创建'}
  />
)

const validateEntry = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = ['请输入标题']
  }
  if (!values.datetime) {
    errors.datetime = ['请输入时间']
  }
  if (!values.payment) {
    errors.payment = ['请选择支付方式']
  }
  if (!values.level) {
    errors.level = ['请选择级别']
  }

  return errors
}
export const MainCategorySelector = ({ curCategory = null}) => {
  const amount = useWatch({ name: 'amount' });
  const type = amount > 0 ? 'I' : (amount < 0 ? 'E' : 'O');
  const { setValue } = useFormContext(); // Get the setValue function from useFormContext

  const handleCategoryChange = (value) => {
    setValue('category', value || 86); // Set the value of the "category" attribute in the form
  };

  return (
    <CategorySelector onChange={handleCategoryChange} type={type} curCategory={curCategory}/>
  );
};

export const TypeSelector = ({ mode }) => {
  const { setValue } = useFormContext()
  const amount = useWatch({ name: 'amount' })
  useEffect(() => {
    setValue('type', amount > 0 ? 'I' : (amount < 0 ? 'E' : 'O'))
  }, [amount, setValue])
  return (
    <SelectInput
      source="type" label="类型" required disabled={mode === 'combine'}
      sx={{ 'display': 'none' }}
      choices={BILL_TYPE}/>
  )
}

export const LevelSelector = () => {
  const { setValue } = useFormContext()
  const parentId = useWatch({ name: 'parent' })
  const { data } = useGetOne('entry', { id: parentId }, { enabled: !!parentId })
  useEffect(() => {
    if (data) {
      setValue('level', data.level + 1)
    }
  }, [data, setValue])
  return (
    <NumberInput sx={{ 'width': 100 }} source="level" label="级别" required
                 disabled/>
  )
}

export const EntryCreateForm = ({ mode, info }) => {
  return (
    <Create redirect="list"
            aside={mode === 'combine' ? <Aside info={info}/> : null}>
      <SimpleForm
        redirect="list"
        toolbar={<BottomToolbar mode={mode}/>}
        validate={validateEntry}
        sx={{ maxWidth: 800 }}
        // Here for the GQL provider
        defaultValues={
          info ? info.defaultValues : {
            title: '',
            content: '',
            amount: null,
            datetime: new Date(),
            payment: 'C',
            level: 1,
            category: 86,
          }
        }
      >
        <Box sx={{ 'width': 1 }} spacing={1}>

          <Typography variant={'h6'} sx={{ 'mb': 1 }}>
            {mode === 'combine'
              ? `将 ${info?.count} 项合并到新条目`
              : '新建条目'}
          </Typography>

          <Stack spacing={0.5}>
            <TextInput source="title" label="标题"
                       resettable isRequired fullWidth/>
            <TextInput source="content" label="详情" fullWidth multiline/>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }}
                 sx={{ flexWrap: 'wrap' }} spacing={{ xs: 1, sm: 2 }}
                 justifyContent="flex-start">
            <NumberInput sx={{ 'width': 150 }}
                         source="amount" label="金额" isRequired
                         disabled={mode === 'combine'}
            />
            <LevelSelector/>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }}
                 sx={{ flexWrap: 'wrap' }} spacing={{ xs: 1, sm: 2 }}
                 justifyContent="flex-start">
            <DateTimeInput source={'datetime'} label="时间" isRequired/>
            <SelectInput
              source="payment" label="支付方式" required choices={PAYMENT}/>
          </Stack>

          <Stack>
            <ReferenceInput source="parent" reference="entry">
              <AutocompleteInput source="id" label="上级账单"
                                 disabled={true}
                                 sx={{'display': mode=== 'create' ? 'none' : 'block'}}
              />
            </ReferenceInput>
            <Typography sx={{ 'mb': 1 }}>账单类别</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }}
                   sx={{ flexWrap: 'wrap' }} spacing={1}
                   justifyContent="flex-start">
              <MainCategorySelector/>
            </Stack>
            <ReferenceArrayInput source="label" reference="label" label="标签"/>
          </Stack>

          <TypeSelector mode={mode}/>
        </Box>
      </SimpleForm>
    </Create>
  )
}
