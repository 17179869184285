import {
  Create,
  Datagrid, DateField,
  Error,
  FileField, FileInput,
  List,
  Loading, SelectInput, SimpleForm,
  TextField, TextInput, useAuthProvider,
  useDataProvider,
} from 'react-admin'

import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { PAYMENT } from './constant'

const UploadForm = (props) => {
  const authProvider = useAuthProvider()

  const handleSubmit = async (values) => {
    try {
      // console.log('values', values)
      const formData = new FormData()
      formData.append('payment', values.payment)
      formData.append('file', values.file.rawFile)
      const token = localStorage.getItem('access')
      const response = await fetch('/api/upload/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      // console.log(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <Typography variant="h6">上传账单文件</Typography>
        <Box sx={{ 'maxWidth': 400 }}>
          <SelectInput
            source="payment" label="支付方式" required choices={PAYMENT}/>
          <FileInput source="file">
            <FileField source="src" title="title"/>
          </FileInput>
        </Box>
      </SimpleForm>
    </Create>
  )
}

export const UploadPage = () => {

  return (
    <Box divider={<Divider/>} space={2}>
      <UploadForm/>
      <List>
        <Datagrid>
          <TextField source="id"/>
          <TextField source="title"/>
          <TextField source="payment"/>
          <DateField source="datetime" showTime/>
          <FileField source="file" title="文件"/>
        </Datagrid>
      </List>
    </Box>
  )

}