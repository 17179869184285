import { EntryCreateForm } from './EntryCreateForm'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Loading, useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'

export const EntryCreate = () => (
  <EntryCreateForm mode="create"/>
)
export const EntryCombine = () => {
  const location = useLocation()
  const dataProvider = useDataProvider()
  const ids = new URLSearchParams(location.search).get('ids')
  const idsList = ids.split(',').map(id => parseInt(id))
  const { data, isLoading, error } = useQuery(
    ['entry', { ids: idsList }],
    () => dataProvider.getMany('entry',
      { ids: idsList, sort: { field: 'datetime', order: 'DESC' } }))
  if (isLoading) return <Loading/>
  if (error) return <p>ERROR</p>
  if (!data) return null

  const totalAmount = data.data.reduce((acc, cur) => acc + cur.amount, 0)
    .toFixed(2)

  const info = {
    'defaultValues': {
      title: '',
      content: '',
      amount: totalAmount,
      datetime: data.data[0].datetime,
      payment: data.data.reduce((acc, cur) => acc === cur.payment ? acc : 'M',
        data.data[0].payment),
      level: data.data[0].level,
      category: 86,
      subs: idsList,
      parent: data.data[0].parent,
    },
    'count': data.data.length,
    'data': data.data,
    'ids': idsList,
  }
  return <EntryCreateForm mode="combine" info={info}/>

}