import ReactEcharts from 'echarts-for-react';
import {getWithToken} from "../fetchWithToken";
import {useEffect, useState} from "react";

export const YearlyProfit = ({year}) => {
    const [data, setData] = useState({})
    useEffect(() => {
        getWithToken('/api/statistic/profit/', {
            'year': year
        }).then((data) => {
            if (data && data.profit && data.income && data.expense) {
                data.profit = data.profit.map(num => parseFloat(num.toFixed(0)));
                data.income = data.income.map(num => parseFloat(num.toFixed(0)));
                data.expense = data.expense.map(num => parseFloat(num.toFixed(0)));
                data.accumulatedProfit = data.profit.reduce((acc, value) => {
                    if (acc.length > 0) {
                        acc.push(acc[acc.length - 1] + value);
                    } else {
                        acc.push(value);
                    }
                    return acc;
                }, []);
                setData(data)
            }
        })
    }, [year]);
    let option;
    if (data){
        option = {
            // title: {
            //     text: '年度收支统计',
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['净收入', '支出', '收入', '储蓄']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            yAxis: [{type: 'value'}],
            xAxis: [{
                type: 'category',
                axisTick: {
                    show: false
                },
                data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            }],
            series: [
                {
                    name: '净收入',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'bottom'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.profit
                },
                {
                    name: '收入',
                    type: 'bar',
                    stack: 'Total',
                    label: {
                        show: true,
                        position: 'top',
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.income
                },
                {
                    name: '支出',
                    type: 'bar',
                    stack: 'Total',
                    label: {
                        show: true,
                        position: 'bottom'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.expense
                },
                {
                    name: '储蓄',
                    type: 'line',
                    data: data.accumulatedProfit
                }

            ]
        }
    }
    return <ReactEcharts option={option} style={{height: '600px'}}/>;
};
